import http from '../http-common';
class AuthService {
  async login(username, password) {
    //console.log('Login user', username, password);
    const response = await http.post('/users/auth', {
      username: username,
      password: password,
    });
    if (response.data.token) {
      const user = JSON.stringify(response.data);
      localStorage.setItem('toySpaceAdmin', user);
    }

    return response.data;
  }

  logout() {
    localStorage.removeItem('toySpaceAdmin');
    window.location = '/';
  }
  async register(username, password) {
    await http.post('/register', {
      username: username,
      password: password,
    });
  }
  async getCurrentUser() {
    try {
      const user = JSON.parse(localStorage.getItem('toySpaceAdmin'));
      if (user) {
        const currentUser = await http.get(`/users/${user.id}`);
        return currentUser.data;
      } else {
        localStorage.removeItem('toySpaceAdmin');
        return false;
      }
    } catch (err) {
      console.log(err);
    }

    // await liff.init({ liffId: '1656911262-me8ZZQ2B' }).catch(err=>{throw err});
    // try{
    //     if (liff.isLoggedIn()){
    //         const lineUser = await liff.getProfile();
    //       return lineUser
    //     }
    //     else {
    //         const user = JSON.parse(localStorage.getItem('user'));
    //           if (user) {
    //           const currentUser = await http.get(`/users/${user.id}`);
    //           return currentUser.data;
    //         } else {
    //           localStorage.removeItem('user');
    //           return false;
    //         }
    //     }
    // }catch (err) {
    //     console.log(err)
    // }
  }
  async isAdmin() {
    const user = await this.getCurrentUser();
    if (user.role !== 'admin') {
      window.location = '/';
    }
  }
}

export default new AuthService();
