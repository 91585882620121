import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AuthenServices from '../../services/authenServices';
import UserServices from '../../services/userServices';
import Iconify from '../Iconify';

const ProfileDetails = () => {
  const [currentUser, setCurrentUser] = useState([]);
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lasttName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const response = await AuthenServices.getCurrentUser();
      setCurrentUser(response);
      if (response.firstName !== null) {
        setFirstName(response.firstName);
      }
      if (response.lastName !== null) {
        setLastName(response.lastName);
      }
      if (response.email !== null) {
        setEmail(response.email);
      }
      if (response.firstName !== null) {
        setPhone(response.phone);
      }
    };
    getUser();
  }, []);

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowConfirmPass = () => {
    setShowConfirmPass((show) => !show);
  };
  const handleSubmitDetails = async () => {
    try {
      const data = {
        firstName: firstName,
        lastName: lasttName,
        email: email,
        phone: phone,
      };
      const response = await UserServices.updateDetails(
        currentUser.user_id,
        data
      );
      if (response.status === 'success') {
        setOpenSuccess(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPass) {
      setErrMsg('รหัสผ่านไม่ตรงกัน');
    } else {
      setErrMsg('');
      const data = {
        password: password,
      };
      const currentUser = await AuthenServices.getCurrentUser();
      try {
        await UserServices.updatePassword(currentUser.user_id, data);
        AuthenServices.logout();
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <form
      autoComplete="off"
      noValidate
      //   {...props}
    >
      <Card>
        <CardHeader title="ข้อมูลส่วนตัว" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="ชื่อ"
                name="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                // onChange={handleChange}
                // value={values.firstName}
                // name="username"
                // onChange={handleChange}
                // value={values.username}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="นามสกุล"
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
                value={lasttName}
                // onChange={handleChange}
                // value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="อีเมล"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                // onChange={handleChange}
                // value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="เบอร์โทรศัพท์"
                name="phone"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                // onChange={handleChange}
                type="number"
                // value={values.phone}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmitDetails}
          >
            บันทึก
          </Button>
        </Box>
      </Card>
      <Card sx={{ mt: 3 }}>
        <CardHeader title="รหัสผ่าน" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label="รหัสผ่าน"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type={showConfirmPass ? 'text' : 'password'}
                label="ยืนยันรหัสผ่าน"
                onChange={(e) => setConfirmPass(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowConfirmPass} edge="end">
                        <Iconify
                          icon={
                            showConfirmPass
                              ? 'eva:eye-fill'
                              : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Grid item md={6} xs={12}>
            <div>
              <span style={{ color: 'red' }}>{errMsg}</span>
            </div>
          </Grid>

          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmitPassword}
          >
            ตั้งค่ารหัสผ่านใหม่
          </Button>
        </Box>
      </Card>
      <Dialog
        open={openSuccess}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-success-dialog-title">สำเร็จ</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-success-dialog-description">
            อัพเดทข้อมูลส่วนตัวสำเร็จ
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleCloseSuccess} autoFocus>
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
export default ProfileDetails;
