import { mockImgAvatar } from '../utils/mockImages';
const avatars = [...Array(8)].map((_, index) => {
  const setIndex = index + 1;

  return {
    cover: mockImgAvatar(setIndex),
    avatar_id: index + 1,
  };
});

export default avatars;
