import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import Category from './pages/Categories';
import NewProduct from './pages/NewProduct';
import EditProduct from './pages/Products/_id';
import Shop from './pages/Shop';
import ToySpace from './pages';
import Profile from './pages/Profile';
import ProductCategory from './pages/Categories/_id';
import OrderList from './pages/Order/OrderList';
import OrderDetails from './pages/Order/OrderDetails';
import ShopBill from './pages/Shop/ShopBill';
import NewPost from './pages/Blog/NewPost';
import Post from './pages/Blog/_id';
import EditPost from './pages/Blog/EditPost';
import Dealer from './pages/Dealer'

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'categories', element: <Category /> },
        { path: 'products', element: <Products /> },
        { path: 'categories/:id', element: <ProductCategory /> },
        { path: 'products/:id', element: <EditProduct /> },
        { path: 'profile', element: <Profile /> },
        { path: 'shop', element: <Shop /> },
        { path: 'shopOrder', element: <ShopBill /> },
        { path: 'new-product', element: <NewProduct /> },
        { path: 'orders', element: <OrderList /> },
        { path: 'order/details/:orderId', element: <OrderDetails /> },
        { path: 'blogs', element: <Blog /> },
	  	{ path: 'newPost', element: <NewPost /> },
        { path: 'blogs/:id', element: <Post /> },
        { path: 'blogs/edit/:id', element: <EditPost /> },
		{ path: 'dealer', element: <Dealer /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        // { path: '/', element: <Navigate to="/dashboard/products" /> },
        { path: '/', element: <ToySpace /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
