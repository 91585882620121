import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import ProductServices from '../services/productServices';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  align: 'center',
  justifyContent: 'center',
};
const PictureInfoDialog = ({ info, onRefresh }) => {
  const [open, setOpen] = React.useState(false);
  const [alt, setAlt] = React.useState(info.alt);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const updateAlt = async () => {
    try {
      const data = {
        alt: alt,
      };
      await ProductServices.updatePictureAlt(info.image_id, data);
      onRefresh();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>Alt</Button>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="toy-space-picture">
            <img src={info.image_url} alt={info.alt} />
          </div>
          <Grid sx={{ mt: 2 }}>
            <TextField
              label="Alt text"
              fullWidth
              value={alt}
              onChange={(e) => setAlt(e.target.value)}
            ></TextField>
          </Grid>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs>
              <Button variant="outlined" onClick={handleClose}>
                ปิด
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={updateAlt}>
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export default PictureInfoDialog;
