import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import FormControl from '@mui/material/FormControl';

import { Grid, MenuItem, Select, TextField } from '@mui/material';
// import ProductServices from '../services/product.service';
import { useParams } from 'react-router';
import { blockInvalidNumber } from './BlockInvalidNumber';
import CategoryServices from '../services/categoryServices';
import ProductServices from '../services/productServices';
import Label from './Label';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditProductInfoDialog({ info, onRefresh }) {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [productDetails, setProductDetails] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  const handleChangeCategory = (e) => {
    setProductDetails((prev) => ({ ...prev, category_id: e.target.value }));
  };
  const handleChangeDescription = (e) => {
    const newData = e.target.value;
    setProductDetails((prev) => ({ ...prev, product_description: newData }));
  };
  const handleChangeDiscount = (e) => {
    const newData = e.target.value;
    setProductDetails((prev) => ({ ...prev, dealer_discount: newData }));
  };
  const handleChangeName = (e) => {
    const newData = e.target.value;
    setProductDetails((prev) => ({ ...prev, product_name: newData }));
  };
  const handleChangePrice = (e) => {
    const newData = e.target.value;
    setProductDetails((prev) => ({ ...prev, price: newData }));
  };
  const handleChangeQuantity = (e) => {
    const newData = e.target.value;
    setProductDetails((prev) => ({ ...prev, product_quantity: newData }));
  };

  const handleClickOpen = async () => {
    await ProductServices.getProductInfo(id).then((response) => {
      setProductDetails(response);
    });
    await CategoryServices.getAllCategories().then((response) => {
      setCategoriesList(response);
    });
    // await ProductServices.getProductPictures(id).then((response) => {
    //   setAllPhotos(response);
    //   const filter = response.filter((p) => p.cover_image === 1);
    //   setValue(filter[0].image_id);
    // });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    // console.log('productDetails', productDetails);
    try {
      await ProductServices.updateProductDetails(id, productDetails);
      onRefresh();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  //   const updateCover = async () => {
  //     const oldCover = allPhotos.filter((p) => p.cover_image === 1)[0].image_id;
  //     if (Number(oldCover) !== Number(value)) {
  //       try {
  //         const oldData = {
  //           cover_image: false,
  //         };
  //         const data = {
  //           cover_image: true,
  //         };
  //         await ProductServices.updateCover(oldCover, oldData);
  //         await ProductServices.updateCover(value, data);
  //         onRefresh();
  //         setOpen(false);
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     } else {
  //       setOpen(false);
  //     }
  //   };

  return (
    <div className="space-bt-left">
      <Button
        sx={{ marginBottom: 3 }}
        variant="contained"
        fullWidth
        onClick={handleClickOpen}
      >
        แก้ไขข้อมูลสินค้า
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              แก้ไขข้อมูลสินค้า {info.name}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSubmit}>
              บันทึก
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <div className="toy-space-container">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Label>ชื่อสินค้า</Label>
                <TextField
                  required
                  fullWidth
                  value={productDetails.product_name}
                  id="product-name"
                  //   label="ชื่อสินค้า"
                  type="text"
                  name="product-name"
                  onChange={handleChangeName}
                  // onChange={(event) => setProductName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Label> ประเภทสินค้า</Label>
                <FormControl sx={{ minWidth: 120 }} fullWidth required>
                  {/* <InputLabel id="toy-space-category-label">
                    ประเภทสินค้า
                  </InputLabel> */}
                  <Select
                    labelId="toy-space-category-label"
                    id="toy-space-category"
                    value={productDetails.category_id}
                    onChange={handleChangeCategory}
                    //   value={category}
                    // label="ประเภทสินค้า"
                    //   onChange={handleChangeCat}
                    fullWidth
                  >
                    {categoriesList.map((val, key) => {
                      return (
                        <MenuItem value={val.category_id} key={key}>
                          {val.category_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Label> รายละเอียด</Label>
                <TextField
                  fullWidth
                  id="description"
                  value={productDetails.product_description}
                  //   label="รายละเอียด"
                  type="text"
                  name="description"
                  onChange={handleChangeDescription}
                  //   onChange={(event) => setDescription(event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Label> ราคา</Label>
                <TextField
                  required
                  fullWidth
                  id="product-price"
                  value={productDetails.price}
                  //   label="ราคา"
                  type="text"
                  name="product-price"
                  onKeyDown={blockInvalidNumber}
                  onChange={handleChangePrice}
                  //   onChange={(event) => setPrice(event.target.value)}
                />
              </Grid>
			  <Grid item xs={12}>
                <Label>ส่วนลดตัวแทน(%)</Label>
                <TextField
                  required
                  fullWidth
                  id="product-dealer-discount"
                  value={productDetails.dealer_discount}
                  //   label="ราคา"
                  type="text"
                  name="product-dealer-discount"
                  onKeyDown={blockInvalidNumber}
                  onChange={handleChangeDiscount}
                  //   onChange={(event) => setPrice(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Label>จำนวน</Label>
                <TextField
                  required
                  fullWidth
                  id="product-quantity"
                  value={productDetails.product_quantity}
                  //   label="จำนวน"
                  type="text"
                  name="product-quantity"
                  onKeyDown={blockInvalidNumber}
                  onChange={handleChangeQuantity}
                  //   onChange={(event) => setQuantity(event.target.value)}
                />
              </Grid>
            </Grid>
          </div>
        </List>
      </Dialog>
    </div>
  );
}
