import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import {
  Alert,
  Button,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Stack,
} from '@mui/material';
import 'react-quill/dist/quill.snow.css';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import BlogServices from '../../services/blogServices';
import { useParams } from 'react-router';

const Post = () => {
  //   const [title, setTitle] = useState('');
  //   const [value, setValue] = useState('');
  //   const [image, setImage] = useState();
  const { id } = useParams();
  const [blogInfo, setBlogInfo] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  useEffect(() => {
    const getBlog = async () => {
      await BlogServices.getBlogById(id).then((res) => {
        setBlogInfo(res);
      });
    };
    getBlog();
  }, [id]);
  const handleEdit = () => {
    window.location = `/dashboard/blogs/edit/${id}`;
  };

  const handleDelete = async () => {
    setOpenBackdrop(true);
    setOpenError(false);
    setOpenWarning(false);
    try {
      await BlogServices.deleteBlog(id).then((res) => {
        if (res.status === 200) {
          window.location = '/dashboard/blogs';
        } else {
          setOpenError(true);
        }
      });
    } catch (error) {}
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const handleOpenWarning = () => {
    setOpenWarning(true);
  };
  return (
    <Page title="Dashboard: Post | Minimal-UI">
      <Container maxWidth="xl" display="flex">
        <Box sx={{ p: 3, pb: 1 }} dir="ltr" display="relative">
          <div className="upload-blog-image">
            <div className="toy-space-upload-blog">
              <img src={blogInfo.image} alt="" />
            </div>
          </div>
          <Typography variant="h3" sx={{ mt: 4, mb: 4 }}>
            {blogInfo.title}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: blogInfo.article }} />
        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {openError ? (
          <Alert severity="error">
            เกิดข้อผิดพลาด ไม่สามารถลบบทความนี้ได้!
          </Alert>
        ) : null}

        <Stack direction="row" spacing={5} sx={{ marginTop: 4 }}>
          <Button
            variant="outlined"
            color="warning"
            onClick={handleOpenWarning}
          >
            ลบบทความ
          </Button>
          <Button variant="contained" color="warning" onClick={handleEdit}>
            แก้ไขบทความ
          </Button>
        </Stack>
        <Dialog
          open={openWarning}
          onClose={handleCloseWarning}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">ต้องการลบบทความ</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              คุณแน่ใจว่าต้องการลบบทความนี้ ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseWarning} autoFocus>
              ยกเลิก
            </Button>
            <Button onClick={handleDelete} variant="contained" color="error">
              ลบ
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};
export default Post;
