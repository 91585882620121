import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import React from 'react';
// material
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';

import { ProductList } from '../../sections/@dashboard/products';

//

import ProductSevices from '../../services/productServices';
import CategoryServices from '../../services/categoryServices';

// ----------------------------------------------------------------------

export default function ProductCategory() {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getProducts = async () => {
      const response = await ProductSevices.getProductByCat(id);
      setProducts(response);
      await CategoryServices.getCategoryById(id).then((res) => {
        setCategory(res);
        setNewCategory(res.category_name);
      });
    };
    getProducts();
  }, [id]);
  const editCategory = async (e) => {
    e.preventDefault();
    try {
      const response = await CategoryServices.updateById(id, newCategory);
      setCategory(response);
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Page title="Dashboard: Products | Minimal-UI">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" sx={{ mb: 5 }}>
            {category.category_name}
          </Typography>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<Iconify icon="eva:edit-fill" />}
          >
            แก้ไขชื่อประเภท
          </Button>
        </Stack>
        {products.length > 0 ? (
          <ProductList products={products} />
        ) : (
          <Card>
            <CardHeader
              title="ยังไม่มีสินค้าประเภทนี้"
              style={{ color: 'blue' }}
            />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr"></Box>
          </Card>
        )}
      </Container>
      <Dialog
        open={open}
        onClose={handleClose}
        component="form"
        onSubmit={editCategory}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>แก้ไขประเภทสินค้า</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="category"
            label="ชื่อประเภท"
            type="text"
            value={newCategory}
            fullWidth
            variant="standard"
            onChange={(event) => setNewCategory(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            ยกเลิก
          </Button>
          <Button variant="contained" type="submit">
            แก้ไข
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
