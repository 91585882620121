import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Grid, List, ListItem, Button, Stack } from '@mui/material';

import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import ProductServices from '../../services/productServices';
import ChangeCoverDialog from '../../components/ChangeCoverDialog';
import EditProductInfoDialog from '../../components/EditProductInfoDialog';
import EditPhotoDialog from '../../components/EditPhotoDialog';

const EditProduct = () => {
  const { id } = useParams();
  const [productInfo, setProductInfo] = useState([]);
  const [allPhotos, setAllPhotos] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [arrow, setArrow] = useState(false);
  useEffect(() => {
    const getProductDetails = async () => {
      const response = await ProductServices.getProductInfo(id);
      // console.log('Product info', response);
      setProductInfo(response);
      await ProductServices.getProductPictures(id).then((res) => {
        setAllPhotos(res);
        if (res.length > 1) {
          setArrow(true);
        }
        // console.log(res);
      });
    };
    getProductDetails();
  }, [id]);
  const fadeProperties = {
    infinite: false,
    indicators: true,
    autoplay: false,
    arrows: arrow,
  };
  const refreshPhoto = async () => {
    await ProductServices.getProductPictures(id).then((response) => {
      setAllPhotos(response);
    });
  };
  const refreshInfo = async () => {
    await ProductServices.getProductInfo(id).then((response) => {
      setProductInfo(response);
    });
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const handleOpenWarning = () => {
    setOpenWarning(true);
  };

  const deleteProduct = async () => {
    const productID = productInfo.product_id;
    try {
      await ProductServices.deleteProduct(productID);
      window.location = '/dashboard/products';
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="toy-space-container ">
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <div className="slide-container">
            <Fade {...fadeProperties}>
              {allPhotos.map((slideImage, index) => (
                <div className="slide" key={index}>
                  <div
                    style={{ backgroundImage: `url(${slideImage.image_url})` }}
                  ></div>
                </div>
              ))}
            </Fade>
          </div>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <ChangeCoverDialog
              info={productInfo}
              photos={allPhotos}
              onRefresh={refreshPhoto}
            />
            <EditPhotoDialog
              info={productInfo}
              photos={allPhotos}
              onRefresh={refreshPhoto}
            />
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6} xs={12}>
          <List>
            <ListItem>ชื่อสินค้า: {productInfo.product_name}</ListItem>
            <ListItem>ประเภท: {productInfo.category_name}</ListItem>
            <ListItem>รายละเอียด: {productInfo.product_description}</ListItem>
            <ListItem>ราคา: {productInfo.price}</ListItem>
			<ListItem>ส่วนลดตัวแทน(%): {productInfo.dealer_discount}</ListItem>
            <ListItem>จำนวน: {productInfo.product_quantity}</ListItem>
            <EditProductInfoDialog info={productInfo} onRefresh={refreshInfo} />

            <Button
              fullWidth
              variant="outlined"
              color="warning"
              sx={{ marginBottom: 10 }}
              onClick={handleOpenWarning}
            >
              ลบสินค้า
            </Button>
          </List>
        </Grid>
      </Grid>
      <Dialog
        open={openWarning}
        onClose={handleCloseWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ต้องการลบสินค้า</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            คุณแน่ใจว่าต้องการลบสินค้านี้ออกจากระบบ ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarning} autoFocus>
            ยกเลิก
          </Button>
          <Button onClick={deleteProduct}>ลบ</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default EditProduct;
