import http from '../http-common';
class BlogServices {
  createBlog = async (blog) => {
    // console.log('from services:', blog);
    try {
      const response = await http.post('/blog', blog);
      return response;
    } catch (err) {
      console.log(err);
    }
  };
  deleteBlog = async (blogId) => {
    try {
      const response = await http.delete(`/blog/${blogId}`);
      return response;
    } catch (err) {
      console.log(err);
    }
  };
  getAllBlogs = async () => {
    try {
      const response = await http.get('/blog');
      return response;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  getBlogById = async (blogId) => {
    try {
      const response = await http.get(`/blog/${blogId}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  updateById = async (blogId, data) => {
    try {
      const response = await http.put(`/blog/${blogId}`, data);
	  //console.log("Update Response:",response);
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

export default new BlogServices();
