// import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import React from 'react';
// material
import {
  AppBar,
  Box,
  Card,
  Container,
  Dialog,
  Grid,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  List,
  ListItem,
  Button,
  Slide,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// components
import Page from '../../components/Page';
import Moment from 'moment';
import { StyledTableCell } from '../../components/StyledTableCell';
import Logo from '../../components/Logo';
import ReactToPrint from 'react-to-print';
import OrderServices from '../../services/orderServices';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// ----------------------------------------------------------------------
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderDetails() {
  const { orderId } = useParams();
  const [address, setAddress] = useState('');
  const [orderDetail, setOrderDetail] = useState([]);
  const [bill, setBill] = useState([]);
  const [open, setOpen] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [disableGenerateBillBtn, setDisableGenerateBillBtn] = useState(false);
  let componentRef = useRef();

  useEffect(() => {
    const getOrderDetail = async () => {
      await OrderServices.getOrderById(orderId).then((res) => {
        OrderServices.getDealerAddress(res.dealer_id).then((response) => {
          if (response.data !== '') {
            const dealerAddress =
              response.data.address1 + response.data.address2;
            setAddress(dealerAddress);
          }
        });
        setOrderDetail(res);
      });
    };
    getOrderDetail();
  }, [orderId]);

  const ccyFormat = (num) => {
    return Number(num).toFixed(2);
  };
  const generateBill = async () => {
	setOpenBackdrop(true);
	setDisableGenerateBillBtn(true);
    const data = {
      order_id: orderDetail.order_id,
      order_text_id: orderDetail.order_text_id,
      bill_detail: orderDetail.order_details,
      order_totalPrice: orderDetail.order_totalPrice,
      discount: orderDetail.discount,
      total: orderDetail.total,
      dealer_name: orderDetail.first_name + ' ' + orderDetail.last_name,
      address: address,
    };
    try {
      await OrderServices.createBill(data).then((res) => {
        if (res.status === 200) {
          const data = {
            order_status: 'complete',
          };
          updateData(data);
        }
      });
    } catch (error) {
      console.log(error);
    }
    // console.log('Bill data:', data);
  };
  const updateData = async (data) => {
    try {
      await OrderServices.updateOrderStatus(orderDetail.order_id, data);
      await OrderServices.getOrderById(orderDetail.order_id).then((res) => {
        setOrderDetail(res);
        setOpenBackdrop(false);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getBillData = async () => {
    try {
      await OrderServices.getBill(orderDetail.order_id).then((res) => {
        //console.log('Bill data:', res.data);
        setBill(res.data);
        setOpen(true);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderDetails = () => {
    if (orderDetail.order_id !== undefined) {
      return (
        <>
          <List>
            <ListItem>
              <Typography>
                คำสั่งซื้อที่: {orderDetail.order_text_id}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                จากคุณ: {orderDetail.first_name} {orderDetail.last_name}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                วันที่สั่งซื้อ:{' '}
                {Moment(orderDetail.created_at).format('DD/MM/YYYY HH:mm:ss')}
              </Typography>
            </ListItem>
            <ListItem>
              <Grid container>
                <Grid item sx={{ marginRight: 1 }}>
                  <Typography>สถานะ:</Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={
                      orderDetail.order_status === 'complete'
                        ? { color: 'green' }
                        : { color: 'orange' }
                    }
                  >
                    {orderDetail.order_status}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            {orderDetail.order_status !== 'complete' && (
              <ListItem>
                <Stack direction="row">
                  <Typography>ที่อยู่ลูกค้า:</Typography>
                  <Box sx={{ marginLeft: 2 }} width={300}>
                    <TextField
                      id="address"
                      multiline
                      rows={4}
                      value={address}
                      fullWidth
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Box>
                </Stack>
              </ListItem>
            )}
            <ListItem>
              <Card sx={{ marginTop: 4, width: '100%' }}>
                <Box
                  sx={{
                    justifyContent: 'center',
                    margin: 4,
                    alignItems: 'center',
                  }}
                >
                  <Table
                    sx={{ minWidth: 250, marginBlock: 4 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>รายการ</StyledTableCell>
                        <StyledTableCell>ราคาต่อหน่วย</StyledTableCell>
                        <StyledTableCell>จำนวน</StyledTableCell>
                        <StyledTableCell align="right">ราคา</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {JSON.parse(orderDetail.order_details).map((row) => (
                        <TableRow key={row.dealer_cart_item_id}>
                          <TableCell>{row.product_name}</TableCell>
                          <TableCell>{row.dealer_price}</TableCell>
                          <TableCell>{row.quantity}</TableCell>
                          <TableCell align="right">
                            {ccyFormat(row.quantity * row.dealer_price)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <Grid item xs={12} sm container>
                    <Grid item xs container spacing={2}>
                      <Grid item sx={{ marginTop: 1 }}>
                        <Typography>รวมค่าสินค้า</Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {ccyFormat(orderDetail.order_totalPrice)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container spacing={2}>
                      <Grid item sx={{ marginTop: 1 }}>
                        <Typography>ส่วนลด</Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography color="red">
                        -{ccyFormat(orderDetail.discount)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container spacing={2}>
                      <Grid item sx={{ marginTop: 1 }}>
                        <Typography>จำนวนเงินรวมทั้งสิ้น</Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography>{ccyFormat(orderDetail.total)}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </ListItem>
          </List>
          <Box
            sx={{
              width: '100%',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            {orderDetail.order_status === 'complete' ? (
              <Button variant="contained" fullWidth onClick={getBillData}>
                ใบเสร็จ
              </Button>
            ) : (
              // <BillDialog id={orderId} />
              // <BillDialog />
              <Button variant="contained" fullWidth onClick={generateBill} disabled={disableGenerateBillBtn}>
                ออกใบเสร็จ
              </Button>
            )}
          </Box>
          {bill.order_id !== undefined && (
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    รายละเอียดบิล
                  </Typography>
                </Toolbar>
              </AppBar>
              <List>
                <div ref={(el) => (componentRef = el)}>
                  <ListItem
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Logo />
                    <Typography variant="h5">ใบเสร็จ</Typography>
                  </ListItem>
                  <ListItem>
                    <Grid container rowSpacing={1}>
                      <Grid item xs={6}>
                        <Box width={350}>
                          <Typography>ร้านทอยสเปซ</Typography>
                          <Typography>
                            17/8 หมู่8 ถนนบางกรวย-ไทรน้อย ตำบลบางกรวย
                            อำเภอบางกรวย จังหวัดนนทบุรี 11130
                          </Typography>
						  <Typography>โทร 064-951-6541</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>เลขที่: {bill.bill_id}</Typography>
                        <Typography>
                          วันที่: {Moment(bill.created_at).format('DD/MM/YYYY')}
                        </Typography>
                        <Typography>
                          คำสั่งซื้อเลขที่: {bill.order_text_id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Box width={350}>
                      <Typography>ลูกค้า: {bill.dealer_name}</Typography>
                      <Typography>ที่อยู่: {bill.address === "" ? "-" : bill.address} </Typography>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <Card sx={{ marginTop: 4, width: '100%' }}>
                      <Box
                        sx={{
                          justifyContent: 'center',
                          margin: 4,
                          alignItems: 'center',
                        }}
                      >
                        <Table
                          sx={{ minWidth: 250, marginBlock: 4 }}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>รายการ</StyledTableCell>
                              <StyledTableCell>ราคาต่อหน่วย</StyledTableCell>
                              <StyledTableCell>จำนวน</StyledTableCell>
                              <StyledTableCell align="right">
                                ราคา
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {JSON.parse(bill.bill_detail).map((row) => (
                              <TableRow key={row.dealer_cart_item_id}>
                                <TableCell>{row.product_name}</TableCell>
                                <TableCell>{row.dealer_price}</TableCell>
                                <TableCell>{row.quantity}</TableCell>
                                <TableCell align="right">
                                  {ccyFormat(row.quantity * row.dealer_price)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>

                        <Grid item xs={12} sm container>
                          <Grid item xs container spacing={2}>
                            <Grid item sx={{ marginTop: 1 }}>
                              <Typography>รวมค่าสินค้า</Typography>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Typography>
                              {ccyFormat(bill.order_totalPrice)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm container>
                          <Grid item xs container spacing={2}>
                            <Grid item sx={{ marginTop: 1 }}>
                              <Typography>ส่วนลด</Typography>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Typography color="red">
                              -{ccyFormat(bill.discount)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm container>
                          <Grid item xs container spacing={2}>
                            <Grid item sx={{ marginTop: 1 }}>
                              <Typography>จำนวนเงินรวมทั้งสิ้น</Typography>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Typography>{ccyFormat(bill.total)}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </ListItem>
                </div>
                <ListItem width="100%">
                  <ReactToPrint
                    trigger={() => (
                      <Button variant="contained">พิมพ์ใบเสร็จ</Button>
                    )}
                    content={() => componentRef}
                  />
                </ListItem>
              </List>
            </Dialog>
          )}
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Page title="Dashboard: Orders | Minimal-UI">
      <Container>
	    <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          //   mb={5}
        >
          <Typography variant="h4" sx={{ mb: 5 }}>
            รายละเอียดคำสั่งซื้อ
          </Typography>
        </Stack>
        {renderDetails()}
      </Container>
    </Page>
  );
}
