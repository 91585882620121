import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
// scroll bar
import 'simplebar/src/simplebar.css';

function AppWithCallbackAfterRender() {
  // useEffect(() => {
  //   console.log('rendered');
  // });

  return (
    <BrowserRouter>
      <App tab="home" />
    </BrowserRouter>
  );
}

const container = document.getElementById('app');
const root = ReactDOM.createRoot(container);
root.render(<AppWithCallbackAfterRender />);
// ReactDOM.render(
//   // <HelmetProvider>
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   // </HelmetProvider>,
//   document.getElementById('root')
// );

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
