import React, { useEffect, useState } from 'react';

// material
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Container,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';

//Service
import CategoryServices from '../../services/categoryServices';

// ----------------------------------------------------------------------
const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter,
}));

export default function Category() {
  const [newCategoty, setNewCategory] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getAllCat = async () => {
      const response = await CategoryServices.getAllCategories();
      setAllCategories(response);
      console.log(response);
    };
    getAllCat();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const addNewCategory = async (e) => {
    e.preventDefault();
    try {
      const data = {
        category_name: newCategoty,
      };

      await CategoryServices.createCategory(data);
      updateCategory();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };
  const updateCategory = async () => {
    await CategoryServices.getAllCategories().then((response) => {
      setAllCategories(response);
    });
  };

  return (
    <Page title="Dashboard: Categories | ToySpace-admin">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" sx={{ mb: 5 }}>
            ประเภทสินค้า
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleClickOpen}
          >
            เพิ่มประเภทสินค้า
          </Button>
        </Stack>

        <Grid container spacing={3}>
          {allCategories.map((card) => (
            <Grid
              item
              key={card.product_id}
              xs={12}
              sm={6}
              md={3}
              component={Link}
              to={{
                pathname: `/dashboard/categories/${card.category_id}`,
              }}
              style={{ textDecoration: 'none' }}
              underline="none"
            >
              <RootStyle>
                <Typography>{card.category_name}</Typography>
                <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                  {'(' + card.NumberOfProducts + ')'}
                </Typography>
              </RootStyle>
            </Grid>
          ))}
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          component="form"
          onSubmit={addNewCategory}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>เพิ่มประเภทสินค้า</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="category"
              label="ชื่อประเภท"
              type="text"
              fullWidth
              variant="standard"
              onChange={(event) => setNewCategory(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              ยกเลิก
            </Button>
            <Button variant="contained" type="submit">
              เพิ่ม
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
