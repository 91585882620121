import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Grid, Table, TableBody, TableRow, TableCell } from '@mui/material';
import ProductServices from '../services/productServices';
import { useParams } from 'react-router';
import PictureInfoDialog from './PictureInfoDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditPhotoDialog({ info, onRefresh }) {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [photoFiles, setPhotoFiles] = React.useState([]);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [photos, setPhotos] = React.useState([]);
  const [deletedPhoto, setDeletedPhoto] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const deleteImage = (imageID) => {
    const s = photos.filter((p) => p.image_id !== imageID);
    setPhotos(s);
    setDeletedPhoto((prev) => prev.concat(imageID));
  };

  const handleClickOpen = async () => {
    await ProductServices.getProductPictures(id).then((response) => {
      setPhotos(response);
    });
    setOpen(true);
  };

  const handleClose = async () => {
    setSelectedFiles([]);
    setPhotoFiles([]);
    setDeletedPhoto([]);
    setOpen(false);
  };
  const handleImageChange = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      const files = Array.from(e.target.files);
      setPhotoFiles((prev) => prev.concat(files));

      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
  };

  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      return (
        <div className="imageHolder" key={index}>
          <img src={photo} alt="" />
          <span className="delete">
            <IconButton
              aria-label="delete"
              onClick={() => removeSelectImage(photo, index)}
            >
              <span className="delete-color">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </span>
            </IconButton>
          </span>
        </div>
      );
    });
  };
  const refreshAlt = async () => {
    await ProductServices.getProductPictures(id).then((response) => {
      setPhotos(response);
    });
  };
  const removeSelectImage = (e, index) => {
    const s = selectedFiles.filter((photo) => photo !== e);
    setSelectedFiles(s);
    photoFiles.splice(index, 1);
  };

  const updateImage = async () => {
    setOpenBackdrop(true);
    if (photoFiles.length > 0) {
      try {
        const productPhoto = await uploadToCloudinary(photoFiles);
        for (let i = 0; i < productPhoto.length; i++) {
          const imageUrl = productPhoto[i].url;
          const imgData = {
            image_url: imageUrl,
            product_id: info.product_id,
            cover_image: false,
          };

          await ProductServices.uploadPictures(imgData);
        }
        onRefresh();
        setSelectedFiles([]);
        setPhotoFiles([]);
        setOpenBackdrop(false);
        setOpen(false);
      } catch (err) {
        console.log(err);
      }
    } else if (deletedPhoto.length > 0) {
      for (let j = 0; j < deletedPhoto.length; j++) {
        const imageID = deletedPhoto[j];
        await ProductServices.deletePhoto(imageID);
      }
      onRefresh();
      setSelectedFiles([]);
      setPhotoFiles([]);
      setDeletedPhoto([]);
      setOpenBackdrop(false);
      setOpen(false);
    } else {
      setOpenBackdrop(false);
      setOpen(false);
    }
  };
  const uploadToCloudinary = async (photo) => {
    const files = Array.from(photo);
    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append(i, file);
    });
    try {
      const response = await ProductServices.uploadToCloud(formData);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="space-bt-left">
      <Button variant="contained" onClick={handleClickOpen}>
        แก้ไขรูปภาพ
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              แก้ไขรูปสินค้า {info.name}
            </Typography>
            <Button autoFocus color="inherit" onClick={updateImage}>
              บันทึก
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <div className="toy-space-container">
            <Table>
              <TableBody>
                {photos.map((photo, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="edit-photo-form">
                        <img src={photo.image_url} alt="" />
                      </div>
                      <Grid sx={{ marginTop: 2 }}>
                        <Typography>Alt text: {photo.alt}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <PictureInfoDialog info={photo} onRefresh={refreshAlt} />
                    </TableCell>
                    <TableCell>
                      <div className="delete-photo">
                        <button
                          disabled={photo.cover_image}
                          variant="outlined"
                          color="error"
                          onClick={() => deleteImage(photo.image_id)}
                        >
                          ลบ
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <br />
            <div className="imgsPreview">{renderPhotos(selectedFiles)}</div>
          </div>

          <div className="toy-space-container">
            <label htmlFor="product-photo">
              <input
                type="file"
                id="product-photo"
                multiple
                onChange={handleImageChange}
                accept="image/*"
                style={{ display: 'none' }}
              />
              <Button fullWidth variant="contained" component="span">
                อัพโหลดรูปสินค้าเพิ่ม
              </Button>
            </label>
          </div>
        </List>
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
