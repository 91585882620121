import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { Table, TableBody, TableRow, TableCell } from '@mui/material';
// import ProductServices from '../services/product.service';
import { useParams } from 'react-router';
import ProductServices from '../services/productServices';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChangeCoverDialog({ info, onRefresh }) {
  //   console.log('info', info);
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [allPhotos, setAllPhotos] = React.useState([]);
  const [value, setValue] = React.useState();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClickOpen = async () => {
    await ProductServices.getProductPictures(id).then((response) => {
      setAllPhotos(response);
      const filter = response.filter((p) => p.cover_image === 1);
      setValue(filter[0].image_id);
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateCover = async () => {
    const oldCover = allPhotos.filter((p) => p.cover_image === 1)[0].image_id;
    if (Number(oldCover) !== Number(value)) {
      try {
        const oldData = {
          cover_image: false,
        };
        const data = {
          cover_image: true,
        };
        await ProductServices.updateCover(oldCover, oldData);
        await ProductServices.updateCover(value, data);
        onRefresh();
        setOpen(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      setOpen(false);
    }
  };

  return (
    <div className="space-bt-left">
      <Button variant="contained" onClick={handleClickOpen}>
        แก้ไขรูปปกสินค้า
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              แก้ไขรูปสินค้า {info.name}
            </Typography>
            <Button autoFocus color="inherit" onClick={updateCover}>
              บันทึก
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <div className="toy-space-container">
            <FormControl>
              <RadioGroup
                aria-labelledby="toy-space-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <Table>
                  <TableBody>
                    {allPhotos.map((photo, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <FormControlLabel
                            value={photo.image_id}
                            control={<Radio />}
                            // label={photo.image_id}
                          />
                        </TableCell>
                        <TableCell>
                          <div className="edit-photo-form">
                            <img src={photo.image_url} alt="" />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </RadioGroup>
            </FormControl>
          </div>
        </List>
      </Dialog>
    </div>
  );
}
