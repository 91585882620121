import React from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
// import { Link , useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import AuthenServices from '../../../services/authenServices';

// ----------------------------------------------------------------------

export default function LoginForm() {
  // const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = React.useState('');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('จำเป็นต้องกรอกชื่อผู้ใช้'),
    password: Yup.string().required('จำเป็นต้องกรอกรหัสผ่าน'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        const username = formik.values.email;
        const password = formik.values.password;
        const response = await AuthenServices.login(username, password);
        if (response.status !== 'success') {
          setErrMsg(response.status);
        } else {
          window.location = '/dashboard/products';
        }
      } catch (err) {
        console.log(err);
      }
      // console.log(formik);
      // navigate('/dashboard', { replace: true });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ my: 3 }}>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="ชื่อผู้ใช้"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="รหัสผ่าน"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify
                      icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
            Forgot password?
          </Link>
        </Stack> */}
        <div>
          <span style={{ color: 'red' }}>{errMsg}</span>
        </div>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ mt: 2 }}
        >
          เข้าสู่ระบบ
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
