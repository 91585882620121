import http from '../http-common';
class UserServices {
  updateDetails = async (userID, data) => {
    try {
      const response = await http.put(`/users/update-details/${userID}`, data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  updatePassword = async (userID, data) => {
    try {
      await http.put(`/users/update-password/${userID}`, data);
    } catch (err) {
      console.log(err);
    }
  };
  updateProfilePic = async (userID, data) => {
    try {
      await http.put(`/users/update-profile/picture/${userID}`, data);
    } catch (err) {
      console.log(err);
    }
  };
  getAllDealer = async () => {
    try{
      const response = await http.get(`/dealer`);
      return response;
    }catch(err) {
      console.log(err)
    }
  }
}
export default new UserServices();
