// import { quantity } from '../../../../server/models/productModel';
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  UPDATE_CART,
  CLEARE_CART,
} from './types';

export const addToCart = (product) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
  let alreadyExists = false;
  // const proItems = product.items;
  // const proColors = product.colors;

  cartItems.forEach((x) => {
    if (x.product_id === product.product_id) {
      alreadyExists = true;
      console.log('Already exists');
      console.log('Product', product);
      // const quantity = product.quantity;
      x.quantity += product.quantity;
      x.total_price += product.total_price;
      // for (let i = 0; i < proColors.length; i++) {
      //   const colorID = proColors[i].color_id;
      //   const filter = x.colors.filter((color) => color.color_id === colorID);
      //   const filterItems = proItems.filter(
      //     (item) => item.color_id === colorID
      //   );
      //   console.log('filter items', filterItems);
      //   if (filter.length > 0) {
      //     for (let j = 0; j < filterItems.length; j++) {
      //       const stockID = filterItems[j].stock_id;
      //       const newQuantity = Number(filterItems[j].quantity);
      //       const newData = x.items.map((row) => {
      //         if (row.stock_id === stockID) {
      //           const quantity = Number(row.quantity) + Number(newQuantity);
      //           return {
      //             ...row,
      //             quantity,
      //           };
      //         }
      //         return row;
      //       });
      //       console.log('newData', newData);
      //       x.items = newData;
      //     }
      //   } else {
      //     x.colors.push(proColors[i]);
      //     x.items.push(...filterItems);
      //   }
      // }
    }
  });
  if (!alreadyExists) {
    console.log('Not exists');
    cartItems.push({ ...product });
    console.log('Cart items', cartItems);
  }
  dispatch({
    type: ADD_TO_CART,
    payload: { cartItems },
  });
  localStorage.setItem('shopCartItems', JSON.stringify(cartItems));
};

export const updateCart = (product) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
  const proItems = product.items;
  const proColors = product.colors;
  cartItems.forEach((x) => {
    if (x.product_id === product.product_id) {
      x.items = proItems;
      x.colors = proColors;
    }
  });
  dispatch({
    type: UPDATE_CART,
    payload: { cartItems },
  });
  localStorage.setItem('shopCartItems', JSON.stringify(cartItems));
};

export const removeFromCart = (product) => (dispatch, getState) => {
  // console.log('Product', product);
  const cartItems = getState()
    .cart.cartItems.slice()
    .filter((x) => x.product_id !== product.product_id);
  // console.log('action', cartItems);
  dispatch({ type: REMOVE_FROM_CART, payload: { cartItems } });
  localStorage.setItem('shopCartItems', JSON.stringify(cartItems));
};

export const incrementToCart = (product) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
  const selectProduct = cartItems.find((item) => item.id === product.id);
  const index = cartItems.indexOf(selectProduct);
  const value = cartItems[index];
  value.qty = value.qty + 1;
  value.total = value.qty * value.netPrice;

  dispatch({
    type: INCREASE_QUANTITY,
    payload: { cartItems },
  });
  localStorage.setItem('shopCartItems', JSON.stringify(cartItems));
};

export const decreaseToCart = (product) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
  const selectProduct = cartItems.find((item) => item.id === product.id);
  const index = cartItems.indexOf(selectProduct);
  const value = cartItems[index];
  if (value.qty > 1) {
    value.qty = value.qty - 1;
    value.total = value.qty * value.netPrice;
  }
  dispatch({ type: DECREASE_QUANTITY, payload: { cartItems } });
  localStorage.setItem('shopCartItems', JSON.stringify(cartItems));
};

export const clearCart = () => (dispatch, getState) => {
  const cartItems = [];
  dispatch({ type: CLEARE_CART, payload: { cartItems } });
  // localStorage.removeItem('shopCartItems');
};
