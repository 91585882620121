import http from '../http-common';

class OrderServices {
  async createOrder(orderData) {
    try {
      const res = await http.post('/dealer/order', orderData);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getOrderHistory(dealerId) {
    console.log('Id:', dealerId);
    try {
      const res = await http.get(`/dealer/order/order-history/${dealerId}`);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
  async getOrderById(orderId) {
    // console.log('service:', orderId);
    try {
      const res = await http.get(`/dealer/order/${orderId}`);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
  async getAllPending() {
    // console.log('From pending');
    try {
      const res = await http.get('/dealer/order/list/pending');
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
  async createBill(data) {
    try {
      const res = await http.post(`/dealer/order/bill`, data);
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  async getBill(orderId) {
    try {
      const res = await http.get(`/dealer/order/bill/${orderId}`);
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  async updateOrderStatus(id, data) {
    try {
      const res = await http.put(`/dealer/order/status/${id}`, data);
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  async searchOrder(order) {
    // console.log('from service: ', order);
    try {
      const res = await http.post(`/dealer/order/search/order`, order);
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  async getDealerAddress(dealer_id) {
    try {
      const res = await http.get(`/dealer/address/${dealer_id}`);
      return res;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new OrderServices();
