import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import {
  BlogPostCard,
  BlogPostsSort,
  BlogPostsSearch,
} from '../../sections/@dashboard/blog';
//
import POSTS from '../../_mocks_/blog';
import { useEffect, useState } from 'react';
import BlogServices from '../../services/blogServices';
import { Box, Input, InputAdornment } from '@mui/material';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchData, setSearchData] = useState('');

  useEffect(() => {
    const getAllPost = async () => {
      await BlogServices.getAllBlogs().then((res) => {
        // console.log('AllPost:', res);
        setPosts(res.data);
        setSearchResult(res.data);
      });
    };
    getAllPost();
  }, []);
  const handleSearchInput = (e) => {
    setSearchData(e.target.value);
    const lowerCased = e.target.value.toLowerCase();
    const filterData = posts.filter((item) =>
      item.title.toLowerCase().includes(lowerCased)
    );
    setSearchResult(filterData);
  };

  const handleClearSearch = () => {
    setSearchData('');
    setSearchResult(posts);
  };
  return (
    <Page title="Dashboard: Blog | Minimal-UI">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            บทความ
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/newPost"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            เขียนบทความ
          </Button>
        </Stack>
        <Box sx={{ maxWidth: 350 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            spacing={2}
          >
            <Input
              autoFocus
              fullWidth
              placeholder="Search…"
              onChange={(e) => handleSearchInput(e)}
              value={searchData}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            {/* <Button variant="contained">Search</Button> */}
            <Button variant="contained" onClick={handleClearSearch}>
              Clear
            </Button>
          </Stack>
        </Box>

        {/* <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <BlogPostsSearch posts={posts} />
          <BlogPostsSort options={SORT_OPTIONS} />
        </Stack> */}

        <Grid container spacing={3}>
          {searchResult.length > 0 ? (
            searchResult.map((post, index) => (
              <BlogPostCard key={post.blog_id} post={post} index={index} />
            ))
          ) : (
            <Typography>ไม่พบรายการที่ค้นหา</Typography>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
