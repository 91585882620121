// import { useFormik } from 'formik';
import { useState } from 'react';
import React from 'react';
// material
import {
  Box,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Button,
  Input,
  InputAdornment,
  TextField,
} from '@mui/material';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Moment from 'moment';
import ShopOrderServices from '../../services/shopOrderServices';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';
import ShopBillDetail from './ShopBillDetails';

const ShopBill = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [disableSearch, setDisableSearch] = useState(true);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [date, setDate] = useState(null);
  const [isSearch, setIsSearch] = useState(false);

  const handleSearchInput = (e) => {
    if (e.target.value === '' && date === null) {
      setDisableSearch(true);
    } else if (e.target.value === '' && date === '') {
      setDisableSearch(true);
    } else {
      setDisableSearch(false);
    }
    setSearchData(e.target.value);
  };

  const handleClearSearch = async () => {
    setSearchData('');
    setDate(null);
    setDisableSearch(true);
    setDataNotFound(false);
    setIsSearch(false);
    setSearchResult([]);
  };

  const handleSearchBill = async () => {
    const data = {
      order_text_id: searchData,
      searchDate: date === null ? '' : dayjs(date).format('YYYY-MM-DD'),
    };
    // console.log('Search data:', data);
    await ShopOrderServices.searchOrder(data).then((res) => {
      //   console.log('Search result:', res.data);
      setIsSearch(true);
      if (res.data.length > 0) {
        setDataNotFound(false);
        setSearchResult(res.data);
      } else {
        setDataNotFound(true);
      }
    });
  };

  const handleChangeDate = (newValue) => {
    if (newValue === '' && searchData === '') {
      setDisableSearch(true);
    } else if (newValue === null && searchData === '') {
      setDisableSearch(true);
    } else {
      setDisableSearch(false);
    }
    setDate(newValue);
  };

  return (
    <Page title="Dashboard: Shop Orders | Minimal-UI">
      <Container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            //   mb={5}
          >
            <Typography variant="h4" sx={{ mb: 5 }}>
              ใบเสร็จร้านค้า
            </Typography>
          </Stack>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Input
                  autoFocus
                  fullWidth
                  placeholder="Search…"
                  onChange={(e) => handleSearchInput(e)}
                  value={searchData}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  }
                  sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                />
              </Grid>

              <Grid item xs={12}>
                <DesktopDatePicker
                  label="วันที่"
                  inputFormat="DD/MM/YYYY"
                  value={date}
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>

            {/* </Box> */}
          </Box>
          <Stack direction="row" spacing={4} sx={{ marginTop: 4 }}>
            <Button
              variant="contained"
              onClick={handleSearchBill}
              disabled={disableSearch}
            >
              Search
            </Button>
            <Button variant="contained" onClick={handleClearSearch}>
              Clear
            </Button>
          </Stack>
          {isSearch && searchResult.length > 0
            ? searchResult.map((order) => (
                <Box key={order.order_text_id}>
                  <div className="information-container">
                    <Grid>
                      <Typography>
                        คำสั่งซื้อเลขที่: {order.order_text_id}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography>
                        วันที่สั่งซื้อ:{' '}
                        {Moment(order.created_at).format('DD/MM/YYYY HH:mm:ss')}
                      </Typography>
                    </Grid>
                    <Grid item xs container spacing={2}>
                      <Grid item xs>
                        <ShopBillDetail details={order} />
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              ))
            : null}

          {dataNotFound && (
            <>
              <Card sx={{ height: '100px' }}>
                <Box
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Typography marginTop={5}>ไม่พบคำสั่งซื้อที่ค้นหา</Typography>
                </Box>
              </Card>
            </>
          )}
        </LocalizationProvider>
      </Container>
    </Page>
  );
};

export default ShopBill;
