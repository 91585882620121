import http from '../http-common';
class ProductServices {
  createProduct = async (productData) => {
    try {
      const response = await http.post('/products', productData);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  createSku = async (productID) => {
    try {
      await http.put(`/products/sku/${productID}`);
    } catch (err) {
      console.log(err);
    }
  };
  deletePhoto = async (imageID) => {
    try {
      await http.delete(`/products/photo/${imageID}`);
    } catch (err) {
      console.log(err);
    }
  };
  deleteProduct = async (productID) => {
    try {
      await http.delete(`/products/${productID}`);
    } catch (err) {
      console.log(err);
    }
  };
  getAllProducts = async () => {
    try {
      const response = await http.get('/products');
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  getProductByCat = async (catID) => {
    try {
      const response = await http.get(`/products/category/${catID}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  getProductInfo = async (id) => {
    try {
      const response = await http.get(`/products/${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  getProductPictures = async (productID) => {
    try {
      const response = await http.get(`/uploadPictures/product/${productID}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  updateCover = async (imageID, data) => {
    try {
      await http.put(`/products/cover/${imageID}`, data);
    } catch (err) {
      console.log(err);
    }
  };
  uploadPictures = async (img) => {
    try {
      await http.post('/uploadPictures', img);
    } catch (err) {
      console.log(err);
    }
  };
  updatePictureAlt = async (imageID, image) => {
    try {
      await http.put(`/products/picture/alt/${imageID}`, image);
    } catch (err) {
      console.log(err);
    }
  };

  updateProductDetails = async (productID, productDetails) => {
    // console.log('from service:', productID);
    try {
      await http.put(`/products/${productID}`, productDetails);
    } catch (err) {
      console.log(err);
    }
  };

  uploadToCloud = async (formData) => {
    try {
      const response = await http.post('/image-upload', formData);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  //Decrease stock when order
  decreaseStock = async (productId, data) => {
    // console.log('Hi');
    try {
      await http.put(`/products/decreaseStock/${productId}`, data);
    } catch (err) {
      console.log(err);
    }
  };
}
export default new ProductServices();
