import http from '../http-common';

class ShopOrderServices {
  async createOrder(orderData) {
    try {
      const res = await http.post('/shop/order', orderData);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  async searchOrder(orderData) {
    // console.log('from service', orderData);
    try {
      const res = await http.post('/shop/order/search', orderData);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ShopOrderServices();
