// import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import React from 'react';
import { styled } from '@mui/material/styles';
// material
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Card,
  CardHeader,
  TableBody,
  TableCell,
  TextField,
  Input,
  InputAdornment,
  IconButton,
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { DeleteOutline } from '@mui/icons-material';
// import Iconify from '../components/Iconify';
// import ShopProductList from '../sections/@dashboard/shop/ProductList';
// import {
//   //   ProductSort,
//   ProductList,
//   //   ProductCartWidget,
//   //   ProductFilterSidebar,
// } from '../sections/@dashboard/shop';
// import { Link as RouterLink } from 'react-router-dom';

//

import { connect } from 'react-redux';
import {
  addToCart,
  removeFromCart,
  clearCart,
} from '../store/actions/cartAction';
import { StyledTableCell } from '../components/StyledTableCell';
import ProductServices from '../services/productServices';
import authenServices from '../services/authenServices';
import ShopOrderServices from '../services/shopOrderServices';

// ----------------------------------------------------------------------
const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});
// function ccyFormat(num) {
//   return `${num.toFixed(2)}`;
// }
function ccyFormat(num) {
  return Number(num).toFixed(2);
}
function subtotal(items) {
  return items
    .map(({ total_price }) => total_price)
    .reduce((sum, i) => sum + i, 0);
}

const Shop = ({ addToCart, cartItems, removeFromCart, clearCart }) => {
  const [products, setProducts] = useState([]);
  const [itemQuantity, setItemQuantity] = useState(1);
  const [searchData, setSearchData] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  //   const [cart, setCart] = useState([]);
  // const [openFilter, setOpenFilter] = useState(false);
  useEffect(() => {
    // console.log('cartItems', cartItems);
    const getProducts = async () => {
      const response = await ProductServices.getAllProducts();
      // console.log(response);
      setProducts(response);
      setSearchResult(response);
    };
    getProducts();
  }, []);

  const order = async (product) => {
    // const itemQuantity = 1;
    if (Number(itemQuantity) > product.product_quantity) {
      alert('สินค้าไม่เพียงพอ');
    } else {
      const item = {
        product_id: product.product_id,
        product_name: product.product_name,
        quantity: Number(itemQuantity),
        price: product.price,
        total_price: itemQuantity * product.price,
      };
      addToCart(item);
    }
  };

  const handleSearchInput = (e) => {
    setSearchData(e.target.value);
    const lowerCased = e.target.value.toLowerCase();
    const filterData = products.filter((item) =>
      item.product_name.toLowerCase().includes(lowerCased)
    );
    setSearchResult(filterData);
  };

  const handleClearSearch = () => {
    setSearchData('');
    setSearchResult(products);
  };

  const deleteItem = (product) => {
    // alert('Delete');
    console.log('Delete item:', product);
    removeFromCart(product);
  };

  const checkBill = async () => {
    console.log('order list:', cartItems);
    const user = await authenServices.getCurrentUser();
    // console.log(user);
    const orderData = {
      admin_id: user.user_id,
      username: user.username,
      order_details: JSON.stringify(cartItems),
      total: subtotal(cartItems),
    };
    console.log('orderData', orderData);
    try {
      for (let i = 0; i < cartItems.length; i++) {
        await ProductServices.decreaseStock(
          cartItems[i].product_id,
          cartItems[i]
        );
      }
      await ShopOrderServices.createOrder(orderData).then((res) => {
        console.log('Response:', res);
        if (res.status === 200) {
          clearCart();
        }
      });
    } catch (error) {
      console.log(error);
    }
    // try {
    //   console.log('Order detail', dealerCartItems);
    //   for (let i = 0; i < dealerCartItems.length; i++) {
    //     await ProductServices.decreaseStock(
    //       dealerCartItems[i].product_id,
    //       dealerCartItems[i]
    //     );
    //   }
    //   await OrderServices.createOrder(orderData).then((res) => {
    //     console.log(res);
    //     window.location = `/dealer/order/success/${user.id}/${res.id}`;
    //   });
    // } catch (err) {
    //   console.log(err);
    // }
  };

  return (
    <Page title="Dashboard: Products | ToySpace-admin">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" sx={{ mb: 5 }}>
            Shop
          </Typography>
        </Stack>
        <Box sx={{ maxWidth: 350 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            spacing={2}
          >
            <Input
              autoFocus
              fullWidth
              placeholder="Search…"
              onChange={(e) => handleSearchInput(e)}
              value={searchData}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            {/* <Button variant="contained">Search</Button> */}
            <Button variant="contained" onClick={handleClearSearch}>
              Clear
            </Button>
          </Stack>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              label="จำนวน"
              value={itemQuantity}
              onChange={(e) => setItemQuantity(e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              {searchResult.map((product) => (
                <Grid key={product.product_id} item xs={12} sm={6} md={3}>
                  <Card>
                    <Box sx={{ pt: '100%', position: 'relative' }}>
                      <ProductImgStyle
                        alt={product.alt}
                        src={product.image_url}
                      />
                    </Box>
                    <Stack spacing={2} sx={{ p: 1 }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2" noWrap>
                          {product.product_name}
                        </Typography>
                        <Typography component="span" variant="body1">
                          {'(' + product.product_quantity + ')'}
                        </Typography>
                      </Stack>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => order(product)}
                        disabled={product.product_quantity <= 0}
                      >
                        สั่งซื้อ
                      </Button>
                    </Stack>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{ height: 650, scroll: true }}>
              <CardHeader title="รายการขาย">
                {/* <Typography variant="h4">รายการขาย</Typography> */}
              </CardHeader>
              <TableContainer sx={{ height: 500 }}>
                <Table sx={{ minWidth: 150 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>จำนวน</StyledTableCell>
                      <StyledTableCell>รายการ</StyledTableCell>
                      <StyledTableCell>ราคา</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cartItems.map((row) => (
                      <TableRow key={row.product_id}>
                        <TableCell>{row.quantity}</TableCell>
                        <TableCell>{row.product_name}</TableCell>
                        <TableCell>{row.total_price}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => deleteItem(row)}
                            color="red"
                          >
                            <DeleteOutline />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* <TableRow style={{ color: 'blue' }}>
                      <TableCell colSpan={2}>รวม</TableCell>
                      <TableCell align="right">{subtotal(cartItems)}</TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="toy-space-container">
                <Grid item xs={12} sm container>
                  <Grid item xs container spacing={2}>
                    <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
                      <Typography style={{ color: 'blue' }}>
                        รวมค่าสินค้า
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
                    <Typography style={{ color: 'blue' }}>
                      {ccyFormat(subtotal(cartItems))}
                    </Typography>
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mb: 2 }}
                  onClick={checkBill}
                >
                  เช็คบิล
                </Button>
              </div>
            </Card>
            {/* <Typography variant="h4">รายการขาย</Typography> */}
          </Grid>
        </Grid>

        {/* <ProductList products={products} /> */}
      </Container>
    </Page>
  );
};
export default connect(
  (state) => ({
    cartItems: state.cart.cartItems,
  }),
  { addToCart, removeFromCart, clearCart }
)(Shop);
// export default connect(null, { addToCart })(Shop);
// export default Shop;
// export default connect(null, { addToCart })(Shop);
