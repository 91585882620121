import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import {
  Alert,
  Button,
  Box,
  Container,
  Input,
  Typography,
  TextField,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ProductServices from '../../services/productServices';
import BlogServices from '../../services/blogServices';
import { useParams } from 'react-router';

const EditPost = () => {
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');
  const [image, setImage] = useState([]);
  const [imageFile, setImageFile] = useState([]);
  const { id } = useParams();
  const [blogInfo, setBlogInfo] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);

  useEffect(() => {
    const getBlog = async () => {
      await BlogServices.getBlogById(id).then((res) => {
        setTitle(res.title);
        setValue(res.article);
        setBlogInfo(res);
      });
    };
    getBlog();
  }, [id]);

  const handleChangeImage = (e) => {
    const filesArray = Array.from(e.target.files).map((file) =>
      URL.createObjectURL(file)
    );
    setImage(filesArray[0]);
    setImageFile(e.target.files);
  };

  const submitBlog = async () => {
    setOpenBackdrop(true);
    setUpdateError(false);
    setUpdateSuccess(false);
    try {
      if (imageFile.length > 0) {
        const uploadedImage = await uploadToCloudinary(imageFile);
        const data = {
          title: title,
          article: value,
          image: uploadedImage[0].url,
        };
        await BlogServices.updateById(id, data).then((res) => {
          if (res.status === 200) {
            setUpdateSuccess(true);
            setOpenBackdrop(false);
            setTimeout(() => {
              setUpdateSuccess(false);
            }, 3000);
            // window.location = `/dashboard/blogs/${id}`;
          } else {
            setUpdateError(true);
          }
        });
      } else {
        const data = {
          title: title,
          article: value,
          image: blogInfo.image,
        };
        await BlogServices.updateById(id, data).then((res) => {
          if (res.status === 200) {
            setUpdateSuccess(true);
            setOpenBackdrop(false);
            setTimeout(() => {
              setUpdateSuccess(false);
            }, 3000);
            // window.location = `/dashboard/blogs/${id}`;
          } else {
            setUpdateError(true);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const uploadToCloudinary = async (photo) => {
    const files = Array.from(photo);
    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append(i, file);
    });
    try {
      const response = await ProductServices.uploadToCloud(formData);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Page title="Dashboard: EditPost | Minimal-UI">
      <Container maxWidth="xl" display="flex">
        <Typography variant="h4" gutterBottom>
          แก้ไขบทความ
        </Typography>

        <Box sx={{ p: 3, pb: 1 }} dir="ltr" display="relative">
          <div className="upload-blog-image">
            <div className="toy-space-upload-blog">
              {image.length > 0 ? (
                <img src={image} alt="" />
              ) : (
                <>
                  <img src={blogInfo.image} alt="" />
                </>
              )}
            </div>
            <div className="upload-cover-button">
              <label htmlFor="image">
                <Input
                  accept="image/*"
                  id="image"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleChangeImage}
                />
                <Button variant="contained" component="span">
                  อัพโหลดรูปบทความ
                </Button>
              </label>
            </div>
          </div>
          <TextField
            fullWidth
            id="title"
            label="หัวข้อ"
            type="text"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          ></TextField>
          <Box mt={4}>
            <ReactQuill theme="snow" value={value} onChange={setValue} />
          </Box>

          <Box sx={{ mt: 4 }}>
            {updateError ? (
              <Alert severity="error">
                เกิดข้อผิดพลาด ไม่สามารถลบบทความนี้ได้!
              </Alert>
            ) : null}
            {updateSuccess ? (
              <Alert severity="success">แก้ไขบทความสำเร็จ</Alert>
            ) : null}
          </Box>
          <Button
            variant="contained"
            sx={{ marginTop: 6 }}
            onClick={submitBlog}
          >
            บันทึก
          </Button>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </Page>
  );
};
export default EditPost;
