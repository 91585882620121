import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Grid } from '@mui/material';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { Avatar } from '@mui/material';
// import AuthenServices from '../services/authenServices';
import UserServices from '../services/userServices';

import AVATARS from '../_mocks_/avatars';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChangeProfilePicDialog({ info, onRefresh }) {
  //   console.log('info', info);
  //   const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  //   const { cover } = AVATARS;
  const [value, setValue] = useState();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClickOpen = () => {
    // console.log('test', info);
    setValue(info.avatar);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const updateProfilePic = async () => {
    // console.log('value', value);
    try {
      const data = {
        avatar: value,
      };
      await UserServices.updateProfilePic(info.user_id, data);
      onRefresh();
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Button fullWidth onClick={handleClickOpen}>
        เปลี่ยนรูป
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              เปลี่ยนรูป {info.username}
            </Typography>
            <Button autoFocus color="inherit" onClick={updateProfilePic}>
              บันทึก
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <div className="toy-space-container">
            <FormControl>
              <RadioGroup
                aria-labelledby="toy-space-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <Grid container spacing={3}>
                  {AVATARS.map((photo, index) => (
                    <Grid key={index} item xs={12} sm={6} md={3}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <FormControlLabel
                            value={photo.avatar_id}
                            control={<Radio />}
                          />
                        </Grid>
                        <Grid item>
                          <Avatar src={photo.cover} />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </FormControl>
          </div>
        </List>
      </Dialog>
    </div>
  );
}
