// component
import Iconify from '../../components/Iconify';
// import ShopIcon from '@mui/icons-material/Shop';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'ประเภทสินค้า',
    path: '/dashboard/categories',
    icon: getIcon('eva:grid-fill'),
  },
  {
    title: 'สินค้า',
    path: '/dashboard/products',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'ร้านค้า',
    path: '/dashboard/shop',
    icon: getIcon('eva:browser-fill'),
  },
  {
    title: 'คำสั่งซื้อ',
    path: '/dashboard/orders',
    icon: getIcon('eva:inbox-fill'),
  },
  {
    title: 'ใบเสร็จร้านค้า',
    path: '/dashboard/shopOrder',
    icon: getIcon('eva:file-outline'),
  },
  {
    title: 'บทความ',
    path: '/dashboard/blogs',
    icon: getIcon('eva:message-square-outline'),
  },
  {
    title: 'ตัวแทนจำหน่าย',
    path: '/dashboard/dealer',
    icon: getIcon('eva:people-outline'),
  },
];

export default sidebarConfig;
