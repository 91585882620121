// import React from 'react'

// import Page from '../components/Page'
import { useEffect } from 'react';
import AuthenServices from '../services/authenServices';
const ToySpace = () => {
  useEffect(() => {
    const getAuth = async () => {
      const current = await AuthenServices.getCurrentUser();
      if (current === false) {
        window.location = '/login';
      } else {
        window.location = '/dashboard/products';
      }
    };
    getAuth();
  }, []);

  // return (
  //     <Page title="TOY SPACE| ToySpace-admin">

  //     </Page>

  // )
};
export default ToySpace;
