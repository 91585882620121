// import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import React from 'react';
// material
import {
  Box,
  Container,
  Stack,
  Typography,
  Button,
  Input,
  InputAdornment,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { ProductList } from '../../sections/@dashboard/products';
import { Link as RouterLink } from 'react-router-dom';

//

import ProductSevices from '../../services/productServices';
import CategoryServices from '../../services/categoryServices';

// ----------------------------------------------------------------------

export default function Products() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchData, setSearchData] = useState('');

  useEffect(() => {
    const getProducts = async () => {
      const response = await ProductSevices.getAllProducts();
      setSearchResult(response);
      setProducts(response);
      await CategoryServices.getAllCategories().then((res) => {
        setCategories(res);
      });
    };
    getProducts();
  }, []);

  const handleSearchInput = (e) => {
    setSearchData(e.target.value);
    const lowerCased = e.target.value.toLowerCase();
    const filterData = products.filter((item) =>
      item.product_name.toLowerCase().includes(lowerCased)
    );
    setSearchResult(filterData);
  };

  const handleClearSearch = () => {
    setSearchData('');
    setSearchResult(products);
  };

  return (
    <Page title="Dashboard: Products | Minimal-UI">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" sx={{ mb: 5 }}>
            สินค้าทั้งหมด
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/new-product"
            startIcon={<Iconify icon="eva:plus-fill" />}
            disabled={categories.length === 0}
          >
            เพิ่มสินค้าใหม่
          </Button>
        </Stack>
        <Box sx={{ maxWidth: 350 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            spacing={2}
          >
            <Input
              autoFocus
              fullWidth
              placeholder="Search…"
              onChange={(e) => handleSearchInput(e)}
              value={searchData}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            {/* <Button variant="contained">Search</Button> */}
            <Button variant="contained" onClick={handleClearSearch}>
              Clear
            </Button>
          </Stack>
        </Box>
        <ProductList products={searchResult} />
      </Container>
    </Page>
  );
}
