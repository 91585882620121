import PropTypes from 'prop-types';
// import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    // <RouterLink to="/">
    <div>
      <Box
        component="img"
        src="/static/toyspace-logo.png"
        sx={{ width: 80, height: 80, ...sx }}
      />
    </div>
    // </RouterLink>
  );
}
