import React, { useEffect, useState } from 'react';

// material
import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Input,
  IconButton,
  Container,
  TextField,
  Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { alpha, styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// components
import Page from '../components/Page';
import { blockInvalidNumber } from '../components/BlockInvalidNumber';
import Iconify from '../components/Iconify';
import CategoryServices from '../services/categoryServices';
import ProductServices from '../services/productServices';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(3, 5),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(0),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));

export default function NewProduct() {
  const [category, setCategory] = useState('');
  const [categoriesList, setCategoriesList] = useState([]);
  const [coverPhoto, setCoverPhoto] = useState([]);
  const [coverFile, setCoverFile] = useState([]);
  const [errMsg, setErrMsg] = useState('');
  const [photoFiles, setPhotoFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const formRef = React.useRef();
  const [warning, setWarning] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [discount, setDiscount] = useState('');

  useEffect(() => {
    const getCategories = async () => {
      await CategoryServices.getAllCategories().then((response) => {
        setCategoriesList(response);
      });
    };
    getCategories();
  }, []);
  const addProduct = async (e) => {
    e.preventDefault();
    try {
      if (coverFile.length === 0) {
        setErrMsg('กรุณาใส่รูปปกสินค้า');
        setWarning(true);
      } else {
        setErrMsg('');
        setWarning(false);
        setOpenBackdrop(true);

        const cover = await uploadToCloudinary(coverFile);
        if (cover.length > 0) {
          const coverUrl = cover[0].url;
          try {
            const productData = {
              product_name: productName,
              product_description: description,
              category_id: category,
              price: price,
			  dealer_discount: discount,
              dealer_price: price - (Number(price) * Number(discount)) / 100,
              quantity: quantity,
            };
            const productRes = await ProductServices.createProduct(productData);
            if (productRes) {
              const id = productRes.product_id;
              const imgData = {
                image_url: coverUrl,
                product_id: id,
                cover_image: true,
              };
              await ProductServices.uploadPictures(imgData);
              if (photoFiles.length > 0) {
                const productPhoto = await uploadToCloudinary(photoFiles);
                for (let i = 0; i < productPhoto.length; i++) {
                  const imageUrl = productPhoto[i].url;
                  const imgData = {
                    image_url: imageUrl,
                    product_id: id,
                    cover_image: false,
                  };
                  await ProductServices.uploadPictures(imgData);
                }
              }
            }
            await ProductServices.createSku(productRes.product_id);
            setOpenBackdrop(false);
            window.location = `/dashboard/products`;
          } catch (err) {
            console.log(err);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeCat = (event) => {
    setCategory(event.target.value);
  };
  const handleChangeCover = (e) => {
    const filesArray = Array.from(e.target.files).map((file) =>
      URL.createObjectURL(file)
    );
    setCoverPhoto(filesArray[0]);
    setCoverFile(e.target.files);
  };
  const handleImageChange = (e) => {
    console.log(e.target.files);
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      const files = Array.from(e.target.files);
      setPhotoFiles((prev) => prev.concat(files));

      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
  };

  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      return (
        <div className="imageHolder" key={index}>
          <img src={photo} alt="" />
          <span className="delete">
            <IconButton
              aria-label="delete"
              onClick={() => removeSelectImage(photo, index)}
            >
              <span className="delete-color" style={{ color: 'black' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </span>
            </IconButton>
          </span>
        </div>
      );
    });
  };
  const removeSelectImage = (e, index) => {
    const s = selectedFiles.filter((photo) => photo !== e);
    setSelectedFiles(s);
    photoFiles.splice(index, 1);
  };
  const uploadToCloudinary = async (photo) => {
    const files = Array.from(photo);
    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append(i, file);
    });
    try {
      const response = await ProductServices.uploadToCloud(formData);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Page title="Dashboard: New Product | ToySpace-admin">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">เพิ่มสินค้าใหม่</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Card>
              <CardHeader
                title="รูปสินค้า"
                // subheader="(+43%) than last year"
              />
              <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <div className="upload-photo">
                  <div className="cover-container">
                    <div className="toy-space-upload-cover">
                      {coverPhoto.length > 0 ? (
                        <img src={coverPhoto} alt="" />
                      ) : (
                        <RootStyle>
                          <IconWrapperStyle>
                            <Iconify
                              icon="eva:image-fill"
                              width={24}
                              height={24}
                            />
                          </IconWrapperStyle>

                          <Typography
                            variant="subtitle2"
                            sx={{ opacity: 0.72 }}
                          >
                            Cover Photo
                          </Typography>
                        </RootStyle>
                      )}
                      {/* {renderCover(coverPhoto)} */}
                    </div>
                    <div className="upload-cover-button">
                      <label htmlFor="cover-photo">
                        <Input
                          accept="image/*"
                          id="cover-photo"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={handleChangeCover}
                        />
                        <Button variant="contained" component="span">
                          อัพโหลดรูปปกสินค้า
                        </Button>
                      </label>
                    </div>
                  </div>
                  <div className="upload-photo-button">
                    <label htmlFor="product-photo">
                      <input
                        type="file"
                        id="product-photo"
                        multiple
                        onChange={handleImageChange}
                        accept="image/*"
                        style={{ display: 'none' }}
                      />
                      <Button variant="contained" component="span">
                        อัพโหลดรูปสินค้า
                      </Button>
                    </label>
                  </div>
                  <div className="imgsPreview">
                    {renderPhotos(selectedFiles)}
                  </div>
                </div>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                component="form"
                //noValidate
                onSubmit={addProduct}
                sx={{ mt: 3 }}
                ref={formRef}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="product-name"
                      label="ชื่อสินค้า"
                      type="text"
                      name="product-name"
                      onChange={(event) => setProductName(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ minWidth: 120 }} fullWidth required>
                      <InputLabel id="toy-space-category-label">
                        ประเภทสินค้า
                      </InputLabel>
                      <Select
                        labelId="toy-space-category-label"
                        id="toy-space-category"
                        value={category}
                        label="ประเภทสินค้า"
                        onChange={handleChangeCat}
                        fullWidth
                      >
                        {categoriesList.map((val, key) => {
                          return (
                            <MenuItem value={val.category_id} key={key}>
                              {val.category_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="description"
                      label="รายละเอียด"
                      type="text"
                      name="description"
					  multiline
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="product-price"
                      label="ราคา"
                      type="text"
                      name="product-price"
                      onKeyDown={blockInvalidNumber}
                      onChange={(event) => setPrice(event.target.value)}
                    />
                  </Grid>
				  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="discount"
                      label="ส่วนลดตัวแทน(%)"
                      type="text"
                      name="discount"
                      onKeyDown={blockInvalidNumber}
                      onChange={(event) => setDiscount(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="product-quantity"
                      label="จำนวน"
                      type="text"
                      name="product-quantity"
                      onKeyDown={blockInvalidNumber}
                      onChange={(event) => setQuantity(event.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {warning ? <Alert severity="error">{errMsg}</Alert> : null}
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 6 }}
                  onClick={() => formRef.current.reportValidity()}
                >
                  เพิ่มสินค้า
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </Page>
  );
}
