import React, { useState } from 'react';
import Page from '../../components/Page';
import {
  Button,
  Box,
  Card,
  Container,
  Input,
  Typography,
  TextField,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { alpha, styled } from '@mui/material/styles';
import Iconify from '../../components/Iconify';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ProductServices from '../../services/productServices';
import BlogServices from '../../services/blogServices';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(3, 5),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(0),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));
const NewPost = () => {
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');
  const [image, setImage] = useState([]);
  const [imageFile, setImageFile] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleChangeImage = (e) => {
    const filesArray = Array.from(e.target.files).map((file) =>
      URL.createObjectURL(file)
    );
    setImage(filesArray[0]);
    setImageFile(e.target.files);
  };

  const submitBlog = async () => {
    setOpenBackdrop(true);
    try {
      const uploadedImage = await uploadToCloudinary(imageFile);
      const data = {
        title: title,
        article: value,
        image: uploadedImage[0].url,
      };
      if (uploadedImage?.length > 0) {
        await BlogServices.createBlog(data).then((res) => {
          console.log('response', res);
          if (res.status === 200) {
            setOpenBackdrop(false);
            window.location = '/dashboard/blogs';
          } else {
          }
        });
      }

      // console.log('Blog data:', data);
    } catch (error) {
      console.log(error);
    }
  };
  const uploadToCloudinary = async (photo) => {
    const files = Array.from(photo);
    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append(i, file);
    });
    try {
      const response = await ProductServices.uploadToCloud(formData);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Page title="Dashboard: NewPost | Minimal-UI">
      <Container maxWidth="xl" display="flex">
        <Typography variant="h4" gutterBottom>
          เขียนบทความ
        </Typography>

        <Box sx={{ p: 3, pb: 1 }} dir="ltr" display="relative">
          <div className="upload-blog-image">
            <div className="toy-space-upload-blog">
              {image.length > 0 ? (
                <img src={image} alt="" />
              ) : (
                <RootStyle>
                  <IconWrapperStyle>
                    <Iconify icon="eva:image-fill" width={24} height={24} />
                  </IconWrapperStyle>

                  <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    Image
                  </Typography>
                </RootStyle>
              )}
            </div>
            <div className="upload-cover-button">
              <label htmlFor="image">
                <Input
                  accept="image/*"
                  id="image"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleChangeImage}
                />
                <Button variant="contained" component="span">
                  อัพโหลดรูปบทความ
                </Button>
              </label>
            </div>
          </div>
          <TextField
            fullWidth
            id="title"
            label="หัวข้อ"
            type="text"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          ></TextField>
          <Box mt={4}>
            <ReactQuill theme="snow" value={value} onChange={setValue} />
          </Box>
          <Button
            variant="contained"
            sx={{ marginTop: 6 }}
            onClick={submitBlog}
          >
            อัพโหลดบทความ
          </Button>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </Page>
  );
};
export default NewPost;
