import React, { useEffect, useState } from 'react';
import Page from '../components/Page';
import {
  Avatar,
  Box,
  Card,
  //   CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import AuthenServices from '../services/authenServices';
// import Profile  from '../components/profile/Profile';
// import { UserProfile } from '../components/profile/UserProfile';
import ProfileDetails from '../components/profile/Profile-details';
import ChangeProfilePicDialog from '../components/ChangeProfilePicDialog';
import { mockUserAvatar } from '../utils/mockImages';

const Profile = () => {
  const [profileDetails, setProfileDetails] = useState([]);
  useEffect(() => {
    const getUser = async () => {
      const currentUser = await AuthenServices.getCurrentUser();
      console.log('currentUser', currentUser);
      if (currentUser) {
        setProfileDetails(currentUser);
      } else {
        window.location = '/login';
      }
    };
    getUser();
  }, []);
  const updateProfile = async () => {
    window.location.reload();
    // await AuthenServices.getCurrentUser().then((res) => {
    //   setProfileDetails(res);
    // });
  };
  return (
    <Page title="Dashboard: Profile | ToySpace-admin">
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            ข้อมูลส่วนตัว
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Avatar
                      src={mockUserAvatar(profileDetails)}
                      sx={{
                        height: 64,
                        mb: 2,
                        width: 64,
                      }}
                    />
                    <Typography color="textPrimary" gutterBottom variant="h5">
                      {profileDetails.username}
                    </Typography>
                  </Box>
                </CardContent>
                <Divider />
                <ChangeProfilePicDialog
                  info={profileDetails}
                  onRefresh={updateProfile}
                />
                {/* <CardActions >
                  <ChangeProfilePicDialog
                    info={profileDetails}
                    onRefresh={updateProfile}
                  />
                </CardActions> */}
              </Card>
              {/* <UserProfile user={profileDetails} /> */}
              {/* <AccountProfile /> */}
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <ProfileDetails user={profileDetails} />
              {/* <AccountProfileDetails /> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  );
};
export default Profile;
