// import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import React from 'react';
// material
import {
  Box,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Button,
  Input,
  InputAdornment,
} from '@mui/material';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
// import { Link } from 'react-router-dom';
import Moment from 'moment';

//

// import ProductSevices from '../../services/productServices';
// import CategoryServices from '../../services/categoryServices';
import OrderServices from '../../services/orderServices';
// import Searchbar from '../../layouts/dashboard/Searchbar';

// ----------------------------------------------------------------------

export default function OrderList() {
  const [orderList, setOrderList] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [dataNotFound, setDataNotFound] = useState(false);

  useEffect(() => {
    const getOrders = async () => {
      await OrderServices.getAllPending().then((res) => {
        // console.log('OrderList:', res);
        setOrderList(res);
      });
    };
    getOrders();
  }, []);

  const handleOrderDetails = (orderId) => {
    // console.log('OrderId: ', orderId);
    window.location = `/dashboard/order/details/${orderId}`;
  };
  const handleSearchInput = (e) => {
    // console.log(e);
    setSearchData(e.target.value);
  };

  const handleClearSearch = async () => {
    setSearchData('');
    await OrderServices.getAllPending().then((res) => {
      setOrderList(res);
	  setDataNotFound(false);
    });
  };

  const handleSearchOrder = async (orderTextId) => {
    const data = {
      order_text_id: searchData,
    };
    // console.log('search...', data);
    await OrderServices.searchOrder(data).then((res) => {
      // console.log('Search result:', res);
      setOrderList(res.data);
      if (res.data.length === 0) {
        setDataNotFound(true);
      }
    });
  };
  const renderOrderList = () => {
    if (orderList.length > 0) {
      return (
        <>
          {orderList.map((order) => (
            <Box key={order.order_id}>
              <div
                className="information-container"
                // key={order.order_id}
                onClick={() => handleOrderDetails(order.order_id)}
              >
                <Grid>
                  <Typography>
                    คำสั่งซื้อเลขที่: {order.order_text_id}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography>
                    วันที่สั่งซื้อ:{' '}
                    {Moment(order.created_at).format('DD/MM/YYYY HH:mm:ss')}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item sx={{ marginRight: 1 }}>
                    <Typography>สถานะ:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={
                        order.order_status === 'complete'
                          ? { color: 'green' }
                          : { color: 'orange' }
                      }
                    >
                      {order.order_status}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs container spacing={2}>
                  <Grid item xs></Grid>
                  {/* <OrderDetailDialog orderDetail={order} /> */}
                </Grid>
              </div>
            </Box>
          ))}
        </>
      );
    } else {
      return (
        <>
          {!dataNotFound && (
            <Card sx={{ height: '100px' }}>
              <Box
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography marginTop={5}>ไม่มีคำสั่งซื้อคงค้าง</Typography>
              </Box>
            </Card>
          )}
        </>
      );
    }
  };

  return (
    <Page title="Dashboard: Orders | Minimal-UI">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          //   mb={5}
        >
          <Typography variant="h4" sx={{ mb: 5 }}>
            คำสั่งซื้อทั้งหมด
          </Typography>

          {/* <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/new-product"
            startIcon={<Iconify icon="eva:plus-fill" />}
            disabled={categories.length === 0}
          >
            เพิ่มสินค้าใหม่
          </Button> */}
        </Stack>
        <Box sx={{ maxWidth: 350 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            spacing={2}
          >
            <Input
              autoFocus
              fullWidth
              //   disableUnderline
              placeholder="Search…"
              onChange={(e) => handleSearchInput(e)}
              value={searchData}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={handleSearchOrder}>
              Search
            </Button>
            <Button variant="contained" onClick={handleClearSearch}>
              Clear
            </Button>
          </Stack>
        </Box>
        {renderOrderList()}
        {dataNotFound && (
          <>
            <Card sx={{ height: '100px' }}>
              <Box
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography marginTop={5}>ไม่พบคำสั่งซื้อที่ค้นหา</Typography>
              </Box>
            </Card>
          </>
        )}

        {/* <ProductList products={products} /> */}
      </Container>
    </Page>
  );
}
