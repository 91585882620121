import React, { useRef } from 'react';
import {
  AppBar,
  Box,
  Card,
  Dialog,
  Grid,
  List,
  ListItem,
  Typography,
  Button,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Toolbar,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { StyledTableCell } from '../../components/StyledTableCell';
import Logo from '../../components/Logo';
import ReactToPrint from 'react-to-print';
import Moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShopBillDetail = ({ details }) => {
  const [open, setOpen] = React.useState(false);
  let componentRef = useRef();

  const ccyFormat = (num) => {
    return Number(num).toFixed(2);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>รายละเอียด</Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              รายละเอียดบิล
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <div ref={(el) => (componentRef = el)}>
            <ListItem
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Logo />
              <Typography variant="h5">ใบเสร็จ</Typography>
            </ListItem>
            <ListItem>
              <Grid container rowSpacing={1}>
                <Grid item xs={6}>
                  <Box width={350}>
                    <Typography>ร้านทอยสเปซ</Typography>
                    <Typography>
                      17/8 หมู่8 ถนนบางกรวย-ไทรน้อย ตำบลบางกรวย อำเภอบางกรวย
                      จังหวัดนนทบุรี 11130
                    </Typography>
					<Typography>โทร 064-951-6541</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    วันที่: {Moment(details.created_at).format('DD/MM/YYYY')}
                  </Typography>
                  <Typography>
                    คำสั่งซื้อเลขที่: {details.order_text_id}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Card sx={{ marginTop: 4, width: '100%' }}>
                <Box
                  sx={{
                    justifyContent: 'center',
                    margin: 4,
                    alignItems: 'center',
                  }}
                >
                  <Table
                    sx={{ minWidth: 250, marginBlock: 4 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>รายการ</StyledTableCell>
                        <StyledTableCell>ราคาต่อหน่วย</StyledTableCell>
                        <StyledTableCell>จำนวน</StyledTableCell>
                        <StyledTableCell align="right">ราคา</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {JSON.parse(details.order_details).map((row) => (
                        <TableRow key={row.product_id}>
                          <TableCell>{row.product_name}</TableCell>
                          <TableCell>{row.price}</TableCell>
                          <TableCell>{row.quantity}</TableCell>
                          <TableCell align="right">
                            {ccyFormat(row.quantity * row.price)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Grid item xs={12} sm container>
                    <Grid item xs container spacing={2}>
                      <Grid item sx={{ marginTop: 1 }}>
                        <Typography>จำนวนเงินรวมทั้งสิ้น</Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography>{ccyFormat(details.total)}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </ListItem>
          </div>
          <ListItem>
            <ReactToPrint
              trigger={() => <Button variant="contained">พิมพ์ใบเสร็จ</Button>}
              content={() => componentRef}
            />
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
};

export default ShopBillDetail;
